import React from 'react';
import Icon1 from '../../images/uxui-design.svg';
import Icon2 from '../../images/programming-1.svg';
import Icon3 from '../../images/sport.svg';
import {
    ServicesContainer, 
    ServicesH1, 
    ServicesWrapper, 
    ServicesCard, 
    ServicesIcon, 
    ServicesH2, 
    ServicesP 
} from './ServicesElements';

const Services = () => {
    return (
        <ServicesContainer id="about">
            <ServicesH1>About me</ServicesH1>

            <ServicesWrapper>

                <ServicesCard>
                    <ServicesIcon src={Icon1}/>
                    <ServicesH2>UX/UI designer</ServicesH2>
                    <ServicesP>I have a background in UX/UI design.</ServicesP>
                </ServicesCard>

                <ServicesCard>
                    <ServicesIcon src={Icon2}/>
                    <ServicesH2>Software Engineering</ServicesH2>
                    <ServicesP>Currently a Java developer with experience working in the cloud. I like to work with microservices, REST APIs and containers.</ServicesP>
                </ServicesCard>

                <ServicesCard>
                    <ServicesIcon src={Icon3}/>
                    <ServicesH2>Sport</ServicesH2>
                    <ServicesP>I like to excersice daily, to keep myself healthy, fit and in a productive mindset.</ServicesP>
                </ServicesCard>

            </ServicesWrapper>
        </ServicesContainer>
    );
};

export default Services;