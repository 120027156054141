import React from 'react';
import { 
    FooterContainer,
    FooterWrap,
    FooterLinksContainer,
    FooterLinksWrapper,
    FooterLinkItems,
    FooterLinkTitle,
    FooterLink,
    WebsiteRights
} from './FooterElements';

const Footer = () => {
    return (
        <FooterContainer>
            <FooterWrap>
                <FooterLinksContainer>
                    <FooterLinksWrapper>
                        <FooterLinkItems>
                        
                        </FooterLinkItems>
                    </FooterLinksWrapper>
                </FooterLinksContainer>

                <WebsiteRights>You Hao © {new Date().getFullYear()} </WebsiteRights>
            </FooterWrap>

        </FooterContainer>
    );
};

export default Footer;