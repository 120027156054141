import React, { useEffect, useState } from 'react';
import { FaBars } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
import cv from '../../cv/CV.pdf';
import { animateScroll as scroll } from 'react-scroll';
import { Nav, 
        NavbarContainer, 
        NavLogo, 
        MobileIcon, 
        NavMenu, 
        NavItem, 
        NavLinks,
    } from './NavbarElements';

const Navbar = ({ toggle }) => {
    const [scrollNav, setScrollNav] = useState(false);

    const changeNav = () => {
        if (window.scrollY >= 80) {
            setScrollNav(true);
        } else {
            setScrollNav(false);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", changeNav);
    }, []);
    
    const toggleHome = () => {
        scroll.scrollToTop();
    };

    return (
        <>
        <IconContext.Provider value = {{ color: "#fff" }}>
            <Nav scrollNav={scrollNav}>
                <NavbarContainer>
                    <NavLogo to='/' onClick= {toggleHome}> You Hao </NavLogo>
                    <MobileIcon onClick ={toggle}>
                        <FaBars />
                    </MobileIcon>
                    <NavMenu>
                        <NavItem>
                            <NavLinks to="about"
                            smooth={true} duration={500} spy={true} exact="true" offset={-80}
                            >About me</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks to="first"
                            smooth={true} duration={500} spy={true} exact="true" offset={-80}
                            >How it started</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks to="second"
                            smooth={true} duration={500} spy={true} exact="true" offset={-80}
                            >Technology</NavLinks>
                        </NavItem>

                        <NavItem>
                            <NavLinks to="third"
                            smooth={true} duration={500} spy={true} exact="true" offset={-80}
                            >Sport</NavLinks>
                        </NavItem>
                    </NavMenu>
                </NavbarContainer>
            </Nav>
            </IconContext.Provider>
        </>

    );
};

export default Navbar;