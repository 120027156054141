import React from 'react';

import {SidebarContainer, 
        Icon, 
        CloseIcon, 
        SidebarWrapper, 
        SidebarMenu, 
        SidebarLink, 
        } from './SideBarElements';

const Sidebar = ( {isOpen, toggle} ) => {
    return (
        <SidebarContainer isOpen = {isOpen} onClick = {toggle}>
            <Icon onClick = {toggle} >
                <CloseIcon />
            </Icon>

            <SidebarWrapper>
                <SidebarMenu>
                    <SidebarLink to="about" onClick={toggle}>About me</SidebarLink>
                    <SidebarLink to="first" onClick={toggle}>How it started</SidebarLink>
                    <SidebarLink to="second" onClick={toggle}>Technology</SidebarLink>
                    <SidebarLink to="third" onClick={toggle}>Sport</SidebarLink>
                </SidebarMenu>
                {/* <SideBtnWrap>
                    <SidebarRoute to="/signin">Sign in</SidebarRoute>
                </SideBtnWrap> */}
            </SidebarWrapper>
            
        </SidebarContainer>
    );
};

export default Sidebar;