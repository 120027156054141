
export const homeObjOne = {
    id: 'first',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'How it started.',
    headline: 'UX & UI designer',
    description: 'Studied Communication & Multimedia Design and first started working as a UX & UI designer for 3 years. There I worked closely with front-end developers and fell in love with software development. From there I knew I wanted to do more in programming.',
    buttonLabel: 'Knop tekst',
    imgStart: false,
    alt: 'image',
    img: require("../../images/uxui-design.svg").default,   
    darkText: true
};

export const homeObjTwo = {
    id: 'second',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Passion for technology.',
    headline: 'Software Engineering',
    description: 'Fulltime java developer and currently working currently at Capgemini. I love to build solutions that solves complex problems. I am also studying for certificates or experimenting with different technologies.',
    imgStart: true,
    img: require("../../images/programming-1.svg").default,
    alt: 'image',
    darkText: false
};

export const homeObjThree = {
    id: 'third',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Running, calisthenics, fitness.',
    headline: 'Sport',
    description: 'I like to excercise, because it keeps me healthy, fit and brings a positive mindset that is important for all the things I do daily.',
    buttonLabel: 'tekst knop',
    imgStart: false,
    img: require("../../images/sport.svg").default,
    alt: 'image',
    darkText: true
};