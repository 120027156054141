import React, {useState} from 'react';
import cover from './cover.jpg';
import profile from './profile-yh.png';
import { 
    HeroContainer, 
    HeroBg, 
    HeroContent, 
    HeroH1, 
    HeroP, 
    HeroBtnWrapper, 
    Img,
    Profile
} from './HeroElements';

const index = () => {
    const [hover, setHover] = useState(false);

    const onHover = () => {
        setHover(!hover);
    };

    return(
        <HeroContainer id="home">
            <HeroBg>
                <Img src={cover} alt="mountains"/>
            </HeroBg>
            <HeroContent>
                <HeroH1>Welcome on my website</HeroH1>
                <HeroP>
                I'm a java developer who likes to continuously learn and experiment with new technologies. 
                </HeroP>
                <HeroBtnWrapper>
                    <Profile src={profile} alt="me" draggable="false" />
                </HeroBtnWrapper>
            </HeroContent>
        </HeroContainer>
    );
};

export default index;